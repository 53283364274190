export const IS_PRODUCTION = () => {
  if (process.env.TARGET_IS_PRODUCTION) return true;
  return !/staging|localhost/i.test(window.location.href);
};

// NOTE: Each adapter will provide an implementation, maybe someday???
type APIClientAdapterType = 'apollo-client' | 'fetch' | 'graphql-request' | 'urql';

/**
 * Global application configuration
 */
export const AppConfig = {
  // process.env values
  HOST: process.env.HOST,
  NODE_ENV: process.env.NODE_ENV,
  GQL_ENDPOINT: IS_PRODUCTION()
    ? 'https://hippocrates.production.botmd.io/gql/'
    : 'https://hippocrates.staging.botmd.io/gql/',
  API_ENV: process.env.API_ENV,

  API_CLIENT_ADAPTER: 'graphql-request' as APIClientAdapterType, // NOTE: Default gql client

  LOGIN_URL: IS_PRODUCTION() ? 'https://login.production.botmd.io' : 'https://login.staging.botmd.io',

  CLEO_SESSION_EXCHANGE_PROVIDER_UID: IS_PRODUCTION()
    ? '8yDlLP0ylmQIpKkhuR2dDXZPU9EOPPeG'
    : 'gluy7nAx4yDDwEJIZeiH81zeSmZQsvkw',

  LOGIN_AUTH_PROVIDER_UID: {
    SMS: IS_PRODUCTION() ? 'faraday-auth0-sms' : 'faraday-auth0-sms',
    EMAIL: IS_PRODUCTION() ? 'faraday-auth0-email' : 'faraday-auth0-email',
  },

  CHATBOT_REGISTER_DEVICE: {
    // NOTE: Provider applications 'pusher-faraday'
    providerApplication: IS_PRODUCTION()
      ? 'klrVwq7jQdlLOoL0ZJylVpfh9B2ee2oR'
      : 'klrVwq7jQdlLOoL0ZJylVpfh9B2ee2oR',
    deviceMetadata: {
      channel: 'web',
      platform: 'web',
    },
  },

  STORAGE: {
    LOCAL_WORKSPACE_KEY: 'parkway:workspace',
    LOCAL_PROFILE_KEY: 'parkway:profile',
    COOKIE_TOKEN_KEY: 'parkway:client-token',
    COOKIE_DATA_KEY: 'parkway:client-data',
  },
  DOMAIN_CONFIG_STORAGE_KEY: 'domain:config',
  DOMAIN_AUTH: 'auth.botmd.io',
  REDIRECT_AUTH_CALLBACK_PATH: '/auth/callback',

  PAGINATION_SETTINGS: {
    FIRST_LIMIT_F: 25,
    FIRST_LIMIT_M: 10,
  },
} as const;

export type AppConfigKeyType = keyof typeof AppConfig;

export type AppConfigValType = typeof AppConfig[AppConfigKeyType];

export const getAuthRedirectURL = (): { sms: string; email: string } => {
  const redirectUrl = `${window.location.origin}${AppConfig.REDIRECT_AUTH_CALLBACK_PATH}`;
  const applicationInput = JSON.stringify({
    domain: AppConfig.DOMAIN_AUTH,
  });

  // TODO: Get the actual logo for new parkway
  const themeLogo = JSON.stringify({
    logo: encodeURIComponent(
      'https://botmd-staging-hippocrates-static.s3.amazonaws.com/hospital/PARKWAYRADIO/full_size.png'
    ),
    primaryColor: encodeURIComponent('#256BF6'),
  });

  const loginUrl = (type: string) =>
    `${AppConfig.LOGIN_URL}?uid=${
      AppConfig.LOGIN_AUTH_PROVIDER_UID[type.toUpperCase()]
    }&redirectTo=${redirectUrl}&applicationInput=${applicationInput}&theme=${themeLogo}&universalLogin=true`;

  return {
    sms: loginUrl('sms'),
    email: loginUrl('email'),
  };
};
